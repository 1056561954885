import "./styles/style.scss";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import * as dat from "dat.gui";
import { CubeTexture, CubeTextureLoader } from "three";
import { gsap } from "gsap";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { RGBShiftShader } from "three/examples/jsm/shaders/RGBShiftShader.js";
import { SMAAPass } from "three/examples/jsm/postprocessing/SMAAPass.js";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";

/**
 * Loading - Loaded
 */

const loadingManager = new THREE.LoadingManager(
  // Loaded
  () => {
    const loadingScreen = document.getElementById("loading-screen");
    loadingScreen.classList.add("fade-out");

    // optional: remove loader from DOM via event listener
    loadingScreen.addEventListener("transitionend", onTransitionEnd);
  }
);

// Debug
const gui = new dat.GUI();
dat.GUI.toggleHide();
gui.closed = true;

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

/**
 * Update all materials
 */
const updateAllMaterials = () => {
  scene.traverse((child) => {
    if (
      child instanceof THREE.Mesh &&
      child.material instanceof THREE.MeshStandardMaterial
    ) {
      child.material.envMap = environmentMap;
      child.material.envMapIntensity = 15.5;
    }
  });
};

const cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager);
const environmentMap = cubeTextureLoader.load([
  "/assets/environmentMaps/0/px.jpg",
  "/assets/environmentMaps/0/nx.jpg",
  "/assets/environmentMaps/0/py.jpg",
  "/assets/environmentMaps/0/ny.jpg",
  "/assets/environmentMaps/0/pz.jpg",
  "/assets/environmentMaps/0/nz.jpg",
]);
// scene.background = environmentMap

/**
 * HDRI Texture
 */

// const loader = new THREE.TextureLoader();
// const texture = loader.load(
//     '/assets/Studio.jpg',
//     () => {
//       const rt = new THREE.WebGLCubeRenderTarget(texture.image.height);
//       rt.fromEquirectangularTexture(renderer, texture);

//       scene.background = rt

//     })

// console. log(text)

/**
 * Materials
 */

const AMaterial = new THREE.MeshStandardMaterial({
  color: 0xf3997b,
  side: THREE.DoubleSide,
});
AMaterial.metalness = 0.35;
AMaterial.roughness = 0.65;
AMaterial.transparent = true;
AMaterial.opacity = 0.75;
//  AMaterial.color = new THREE.Color('rgb(95, 60, 48)')

const HMaterial = new THREE.MeshStandardMaterial({
  color: 0x8ec89a,
  side: THREE.DoubleSide,
});
HMaterial.metalness = 0.35;
HMaterial.roughness = 0.65;
HMaterial.transparent = true;
HMaterial.opacity = 0.54;
//  HMaterial.color = new THREE.Color('rgb(56, 78, 60)')

const _Material = new THREE.MeshStandardMaterial({
  color: 0x000000,
  side: THREE.DoubleSide,
});
_Material.metalness = 0.35;
_Material.roughness = 0.65;
_Material.transparent = true;
_Material.opacity = 0.53;

const GMaterial = new THREE.MeshStandardMaterial({
  color: 0x83d0f5,
  side: THREE.DoubleSide,
});
GMaterial.metalness = 0.35;
GMaterial.roughness = 0.65;
GMaterial.transparent = true;
GMaterial.opacity = 0.4;
//  GMaterial.color = new THREE.Color('rgb(51, 82, 96)')

const RectangleMaterial = new THREE.MeshStandardMaterial({
  color: 0xffffff,
  side: THREE.DoubleSide,
});
RectangleMaterial.transparent = true;
RectangleMaterial.opacity = 0.9;

/**
 * Models
 */
const gltfLoader = new GLTFLoader(loadingManager);

gltfLoader.load("/models/AH-GLogo.gltf", (gltf) => {
  const Logo = gltf.scene;
  const A = gltf.scene.children.find((Logo) => Logo.name === "A");
  const H = gltf.scene.children.find((Logo) => Logo.name === "H");
  const _ = gltf.scene.children.find((Logo) => Logo.name === "_");
  const G = gltf.scene.children.find((Logo) => Logo.name === "G");
  const Rectangle = gltf.scene.children.find(
    (Logo) => Logo.name === "Rectangle"
  );
  Rectangle.visible = false;

  A.material = AMaterial;
  H.material = HMaterial;
  _.material = _Material;
  G.material = GMaterial;

  Logo.scale.set(0.5, 0.5, 0.5);

  A.position.z = 0.8;
  H.position.z = 0.1;
  _.position.z = 0.6;
  G.position.z = 0.4;

  gui.add(A.position, "z").min(-1).max(1).step(0.01).name("A");
  gui.add(H.position, "z").min(-1).max(1).step(0.01).name("H");
  gui.add(_.position, "z").min(-1).max(1).step(0.01).name("-");
  gui.add(G.position, "z").min(-1).max(1).step(0.01).name("G");

  scene.add(Logo);

  updateAllMaterials();
});

// Objects
const plane = new THREE.PlaneGeometry(2.5, 2.5);
const circle = new THREE.CircleGeometry(1.05, 65);
const triangle = new THREE.CircleGeometry(1, 2);

// Materials

const CircleMaterial = new THREE.MeshBasicMaterial({ color: 0x83d0f5 });
CircleMaterial.transparent = true;
CircleMaterial.opacity = 0.4;

const RectMaterial = new THREE.MeshBasicMaterial({ color: 0x8ec89a });
RectMaterial.transparent = true;
RectMaterial.opacity = 0.54;

// Mesh
const Circle = new THREE.Mesh(circle, CircleMaterial);
const Rect = new THREE.Mesh(plane, RectMaterial);
const Triangle = new THREE.Mesh(triangle, AMaterial);

Circle.position.z = 0.2;
Rect.position.z = -0.5;
Triangle.position.z = 0;

// scene.add(Circle)

gui.add(Circle.position, "z").min(-1).max(1).step(0.01).name("Circle");
gui.add(Rect.position, "z").min(-1).max(1).step(0.01).name("Rect");
gui.add(Triangle.position, "z").min(-1).max(1).step(0.01).name("Triangle");
// Lights

const pointLight = new THREE.PointLight(0x0063ff, 100);
pointLight.position.x = 2;
pointLight.position.y = 3;
pointLight.position.z = 4;
// scene.add(pointLight)

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

  // Update Composer
  effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  effectComposer.setSize(sizes.width, sizes.height);
});

// const enter = $('.btn')
var enterer = false;

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  75,
  sizes.width / sizes.height,
  0.1,
  100
);
camera.position.x = 0;
camera.position.y = 0;
camera.position.z = 2;

scene.add(camera);

//entering
// enter.click(()=>{
// camera.position.z = enterer?2:1;
// enter[0].innerHTML = enterer?'in': 'in we go!';
// enterer = !enterer;
// })

// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: true,
});
renderer.physicallyCorrectLights = true;
// renderer.outputEncoding = THREE.sRGBEncoding
//  renderer.toneMapping = THREE.ACESFilmicToneMapping
renderer.setClearColor("#180228");
renderer.toneMappingExposure = 1.5;
renderer.setSize(sizes.width, sizes.height);
// renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Post processing
 */
let RenderTargetClass = null;

if (renderer.getPixelRatio() === 1 && renderer.capabilities.isWebGL2) {
  RenderTargetClass = THREE.WebGLMultisampleRenderTarget;
} else {
  RenderTargetClass = THREE.WebGLRenderTarget;
}
//Render Target
const renderTarget = new RenderTargetClass(800, 600, {
  minFilter: THREE.LinearFilter,
  magFilter: THREE.LinearFilter,
  format: THREE.RGBAFormat,
  encoding: THREE.NoToneMapping,
});

// Composer
const effectComposer = new EffectComposer(renderer, renderTarget);
effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
effectComposer.setSize(sizes.width, sizes.height);

//Passes
const renderPass = new RenderPass(scene, camera);
effectComposer.addPass(renderPass);

// RGB Shift Pass
const rgbShiftPass = new ShaderPass(RGBShiftShader);
effectComposer.addPass(rgbShiftPass);
rgbShiftPass.strength = 0.001;
rgbShiftPass.radius = 0.001;
rgbShiftPass.enabled = false;

// BloomPass
const unrealBloomPass = new UnrealBloomPass();
effectComposer.addPass(unrealBloomPass);
unrealBloomPass.strength = 0.1;
unrealBloomPass.radius = 2;
unrealBloomPass.threshold = 0;
// unrealBloomPass.enabled = false

// after all post effects!
if (renderer.getPixelRatio() === 1 && !renderer.capabilities.isWebGL2) {
  const smaaPass = new SMAAPass();
  effectComposer.addPass(smaaPass);
}

gui.add(unrealBloomPass, "enabled");
gui.add(unrealBloomPass, "strength").min(0).max(2).step(0.001);
gui.add(unrealBloomPass, "radius").min(0).max(2).step(0.001);
gui.add(unrealBloomPass, "threshold").min(0).max(1).step(0.001);

function onTransitionEnd(event) {
  event.target.remove();
}

/**
 * Animate
 */

document.addEventListener("mousemove", onDocumentMouseMove);

let mouseX = 0;
let mouseY = 0;

let targetX = 0;
let targetY = 0;

const windowX = window.innerWidth / 2;
const windowY = window.innerHeight / 2;

function onDocumentMouseMove(event) {
  mouseX = event.clientX - windowX;
  mouseY = event.clientY - windowY;
}

const clock = new THREE.Clock();

const tick = () => {
  targetX = mouseX * 0.001;
  targetY = mouseY * 0.001;

  const elapsedTime = clock.getElapsedTime();

  // Update objects
  //  sphere.rotation.y = .5 * elapsedTime
  //  sphere.rotation.y += .5 * (targetX - sphere.rotation.y)
  camera.position.x = Math.cos(0.25 * elapsedTime);
  camera.position.y = Math.sin(0.25 * elapsedTime);
  camera.position.x += 0.5 * (targetX - camera.position.x);
  camera.position.y += 0.5 * (targetY - camera.position.y);

  camera.lookAt(0, 0, 0);

  // Update Orbital Controls
  // controls.update()

  // Render
  // renderer.render(scene, camera)
  effectComposer.render();

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
